import clsx from 'clsx'
import style from './ProgressBar.module.scss'

const pointsData = [1, 2, 3, 4, 5]

const ProgressBar = ({ currentStep, modificator }) => {
  return (
    <div className={clsx(style.wrapper, modificator)}>
      <p className={style.text}>{currentStep}</p>
      <div className={style.pointsWrapper}>
        {pointsData.map((id) => (
          <div
            key={id}
            className={clsx(style.point, {
              [style.active]: id === currentStep,
            })}
          />
        ))}
      </div>
    </div>
  )
}

export default ProgressBar
