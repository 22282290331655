import ProgressBar from '../../../ProgressBar/ProgressBar'
import style from './Quarter1.module.scss'
import Lorenzo from '../../../../assets/img/lorenzo.png'
import Title from '../../../../assets//img/title6.png'

const Quarter1 = ({ currentStep }) => {
  return (
    <div className={style.wrapper}>
      <img className={style.title} src={Title} alt="title" />
      <img className={style.lorenzo} src={Lorenzo} alt="lorenzo" />
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter1
