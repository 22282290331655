import React, { useRef, useState } from 'react'
import Draggable from 'react-draggable'
import Message from '../../../Message/Message.js'
import messageData from '../../../../constants/MessageData'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'
import Pipe from '../../../../assets/img/pipe.png'
import Molekule from '../../../../assets/img/molekule.png'
import Help from '../../../../assets/img/help_molekule.png'
import Video from '../../../../assets/img/video.mp4'
import clsx from 'clsx'
import style from './Quarter3.module.scss'

const Quarter3 = ({ goNextQuarter, currentStep }) => {
  const pipeRef = useRef(null)
  const videoRef = useRef(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [startDragging, setStartDragging] = useState(false)

  const onStart = (e) => {
    console.log('----------------------started')
    setStartDragging(true)
  }

  const onStop = (e) => {
    console.log('----------------------stopped')

    if (pipeRef.current) {
      const pipeRect = pipeRef.current.getBoundingClientRect()
      const moleculeRect = e.target.getBoundingClientRect()
      const videoElement = videoRef.current

      if (
        moleculeRect.x >= pipeRect.x &&
        moleculeRect.x + moleculeRect.width <= pipeRect.x + pipeRect.width &&
        moleculeRect.y >= pipeRect.y &&
        moleculeRect.y + moleculeRect.height <= pipeRect.y + pipeRect.height
      ) {
        setIsSuccess(true)
        videoElement.play()

        videoElement.addEventListener('ended', () => {
          goNextQuarter()
        })
      }
    }
  }

  return (
    <div className={style.wrapper}>
      <div className={style.molekuleWrapper}>
        <div className={style.container}>
          <div className={style.pipeWrapper}>
            <video
              className={clsx(style.video, { [style.active]: isSuccess })}
              ref={videoRef}
              src={Video}
              playsInline
              muted
            />
            <img
              className={clsx(style.pipe, {
                [style.hidden]: isSuccess,
                [style.helping]: startDragging,
              })}
              src={Pipe}
              ref={pipeRef}
              alt="pipe"
            />
          </div>
          <div className={style.helpContainer}>
            <Draggable
              defaultPosition={{ x: 0, y: 0 }}
              onStart={onStart}
              onStop={onStop}
              disabled={isSuccess}
              bounds={'.' + style.container}
            >
              <img
                className={clsx(style.molekule, { [style.hidden]: isSuccess })}
                src={Molekule}
                alt="molekule"
              />
            </Draggable>
            <img
              className={clsx(style.help, { [style.hidden]: startDragging })}
              src={Help}
              alt="helpHand"
            />
          </div>
        </div>
        <div className={style.textMolekule}>
          Волос с низким<br></br>содержанием меланина
        </div>
      </div>
      <Message
        imgModificator={style.img}
        textModificator={style.text}
        wrapperModificator={style.messageWrapper}
        text={messageData[6].text}
      >
        <ProgressBar currentStep={currentStep} />
      </Message>
    </div>
  )
}

export default Quarter3
