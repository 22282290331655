import Arrow from '../../assets/img/btn_arrow.png'
import clsx from 'clsx'
import style from './Button.module.scss'

const Button = ({ id, title, modificator, modificator2, onClick, arrowModificator }) => {
  return (
    <div className={clsx(style.buttonWrapper, modificator)}>
      <button className={clsx(style.button, modificator2)} type="button" onClick={onClick} id={id}>
        {title}
        <img className={clsx(style.arrow, arrowModificator)} src={Arrow} alt="arrow" />
      </button>
    </div>
  )
}

export default Button
