import React, { useState, useEffect } from 'react'
import messageData from '../../../../constants/MessageData.js'
import popupData from '../../../../constants/popupData.js'
import Message from '../../../Message/Message.js'
import Button from '../../../Button/Button.js'
import Popup from '../../../Popup/Popup.js'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'
import clsx from 'clsx'
import style from './Quarter3.module.scss'
import popupStyle from '../../../Popup/Popup.module.scss'

const buttonsData = [
  { id: 1, modificatorWrapper: style.buttonWrapper1, modificatorButton: style.button1 },
  { id: 2, modificatorWrapper: style.buttonWrapper2, modificatorButton: style.button2 },
  { id: 3, modificatorWrapper: style.buttonWrapper3, modificatorButton: style.button3 },
  { id: 4, modificatorWrapper: style.buttonWrapper4, modificatorButton: style.button4 },
]

const Quarter3 = ({ goNextQuarter, currentStep }) => {
  const [selectedId, setSelectedId] = useState()
  const [isSuccess, setIsSuccess] = useState({ 1: null, 2: null, 3: null, 4: null })

  const openPopupHandler = (e) => {
    const target = e.target
    const btn = target.closest('button')
    if (btn) {
      const id = Number(btn.id)
      setSelectedId(id)
    }
  }

  const closePopupHandler = (e) => {
    const target = e.target
    const popup = target.closest(`.${popupStyle.popupWrapper}`)

    const buttonText = target ? target.textContent : ''

    if (popup && target) {
      if (
        ((selectedId === 1 || selectedId === 2 || selectedId === 4) && buttonText === 'взять') ||
        (selectedId === 3 && buttonText === 'не брать')
      ) {
        setIsSuccess((prevState) => {
          const newState = { ...prevState, [selectedId]: true }
          return newState
        })

        setTimeout(() => {
          setSelectedId(undefined)
        }, 500)
      } else if (
        (selectedId === 3 && buttonText === 'взять') ||
        ((selectedId === 1 || selectedId === 2 || selectedId === 4) && buttonText === 'не брать')
      ) {
        setIsSuccess((prevState) => {
          const newState = { ...prevState, [selectedId]: false }
          return newState
        })
      }
    }
  }

  const isSuperSuccess =
    isSuccess[1] && isSuccess[2] && isSuccess[4] && (isSuccess[3] || isSuccess[3] === null)

  console.log('isSuccess', isSuccess)

  useEffect(() => {
    if (isSuperSuccess) {
      const timer = setTimeout(() => {
        goNextQuarter()
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [isSuperSuccess, goNextQuarter])

  return (
    <div
      className={clsx(style.wrapper, {
        [style.wrapperForOpenPopup]: selectedId,
        [style.blocked]: isSuperSuccess,
      })}
    >
      {selectedId && (
        <Popup
          id={selectedId}
          imgModificator={style[`popupImg${selectedId}`]}
          modificator1={clsx(style.button, {
            [style.correct]: isSuccess[selectedId] === true,
            [style.incorrect]: isSuccess[selectedId] === false,
          })}
          caption={popupData[selectedId - 1]?.caption}
          text={popupData[selectedId - 1]?.text}
          onClick={closePopupHandler}
        />
      )}
      <div
        className={clsx(style.buttonContainer, { [style.hidden]: selectedId })}
        onClick={openPopupHandler}
      >
        {buttonsData.map(({ id, modificatorWrapper, modificatorButton }) => (
          <Button
            key={id}
            id={id.toString()}
            modificator={clsx(modificatorWrapper, {
              [style.correct]: isSuccess[id] === true,
            })}
            modificator2={modificatorButton}
          />
        ))}
      </div>
      {!selectedId && (
        <Message
          imgModificator={style.img}
          textModificator={style.text}
          wrapperModificator={style.messageWrapper}
          text={messageData[9].text}
        />
      )}
      <ProgressBar currentStep={currentStep} modificator={style.progressBar} />
    </div>
  )
}

export default Quarter3
