import ProgressBar from '../../../ProgressBar/ProgressBar'
import style from './Quarter1.module.scss'
import Lili from '../../../../assets/img/lili.png'
import Title from '../../../../assets//img/title8.png'

const Quarter1 = ({ currentStep }) => {
  return (
    <div className={style.wrapper}>
      <img className={style.title} src={Title} alt="title" />
      <img className={style.lili} src={Lili} alt="lili" />
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter1
