const messageData = [
  {
    text: 'Космический привет! Ты как раз вовремя! Я провожу опыты с цветовым спектром… </br></br>Для этого мне нужно найти три кристалла основных цветов, что описаны <b>в трёхкомпонентной теории!</b> Из них можно смешивать другие!</br></br>Я думаю, мы друг друга поняли. Поможешь мне?',
  },
  {
    text: 'Найдите подходящие</br> кристаллы',
  },
  {
    text: 'Браво! Согласно трехкомпонентной теории цвета, все цвета можно получить, смешивая только три основных: красный, желтый и синий. Они образуют все оттенки, которые мы видим вокруг.',
    text2:
      'Три основных цвета работают как строительные блоки для всех остальных! Я учитываю это при создании уникальных оттенков для яркости Звёзд нашей вселенной.',
  },
  {
    text: 'Доброе утро! Или вечер? Впрочем, неважно…</br></br> Доктор Мелания, приятно познакомиться. Много столетий я изучаю секрет сияния Звёзд. Чтобы продлить блеск, я создала формулу, аналогичную меланину.</br></br> Я расскажу, как её достать, но сначала проверим, <b>знаешь ли ты, зачем нужен меланин!</b>',
  },
  {
    text: 'Меланин — биологический пигмент, поглощающий ультрафилет. </br><b>Он придаёт цвет и улучшает блеск.</b></br></br> Эти знания — твоё главное оружие против тусклости. </br></br> Чтобы получить формулу, отправляйся к моему коллеге — химику Лоренцо.',
  },
  {
    text: 'Салют! Тебя ко мне отправила Доктор Мелания? Хочешь узнать секрет её формулы?</br></br> Да, это гениальная разработка. <b>Пигменты занимают ту же физиологическую локацию, что и натуральный меланин.</b> Их равномерное распределение позволяет достичь вибрирующего рельефа и сияющего оттенка...</br></br> А зачем рассказывать — давай наглядно покажу!',
  },
  {
    text: 'Примени формулу</br> к волосу',
  },
  {
    text: 'Вот так, представляешь! Это ещё не всё...</br></br> Чтобы формула работала, не вредила Звёздам, а наоборот — ухаживала, <b>нужны подходящие компоненты.</b> За это у нас отвечает Мадам Лили.</br></br> Я думаю, сейчас она как обычно у себя в саду. Оревуар!',
  },
  {
    text: 'Здравствуй, инопланетянин!</br></br> Ты от Лоренцо?</br></br> Тогда приглашаю тебя прогуляться по моему космическому саду! Здесь выращиваются самые удивительные растения во всей Галактике! Тебе предстоит выбрать, что дополнит формулу блеска. <b>Подсказка: один ингредиент явно лишний!',
  },
  {
    text: 'Изучите и выберите ингредиенты',
  },
  {
    text: 'Отличный выбор — никакого борщевика и прочих сорняков!</br></br> Формула для красоты, блеска и сияния Звёзд на 92% состоит из натуральных ингредиентов: <b>масло ши, гиалуроновая кислота, глицерин</b>. Всё это есть у меня!</br></br> Возьми и отправляйся дальше, собери все знания воедино!',
  },
  {
    text: 'Благодарим за то, что ты доставил нам недостающую формулу.</br></br> Чтобы вернуть блеск Звезде, <b>необходимо выбрать эко-упаковку</b>, которая не навредит космической природе.</br></br> Можешь приступать к выбору.',
  },
  {
    text: 'Облегчённая упаковка <b>из переработанного картона и переработанного алюминия</b>. Согласно нашим рассчётам, это оптимальный выбор.</br></br> Можешь отправляться к Звезде и завершить операцию.',
  },
]

export default messageData
