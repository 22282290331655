import { Fragment, useState } from 'react'
import StartPage from '../components/StartPage/StartPage.js'
import Step1 from '../components/Steps/Step1/Step1.js'
import Step2 from '../components/Steps/Step2/Step2.js'
import Step3 from '../components/Steps/Step3/Step3.js'
import Step4 from '../components/Steps/Step4/Step4.js'
import Step5 from '../components/Steps/Step5/Step5.js'
import FinalPage from '../components/FinalPage/FinalPage.js'

const Main = () => {
  const [currentStep, setStep] = useState(0)

  const goNextStep = () => {
    setStep((prev) => prev + 1)
  }

  return (
    <Fragment>
      {currentStep === 0 && <StartPage goNextStep={goNextStep} />}
      {currentStep === 1 && <Step1 goNextStep={goNextStep} currentStep={currentStep} />}
      {currentStep === 2 && <Step2 goNextStep={goNextStep} currentStep={currentStep} />}
      {currentStep === 3 && <Step3 goNextStep={goNextStep} currentStep={currentStep} />}
      {currentStep === 4 && <Step4 goNextStep={goNextStep} currentStep={currentStep} />}
      {currentStep === 5 && <Step5 goNextStep={goNextStep} currentStep={currentStep} />}
      {currentStep === 6 && <FinalPage />}
    </Fragment>
  )
}

export default Main
