import clsx from 'clsx'
import style from './Message.module.scss'

const Message = ({
  text,
  text2,
  imgModificator,
  children,
  wrapperModificator,
  textModificator,
  text2Modificator,
}) => {
  return (
    <div className={clsx(style.wrapper, wrapperModificator)}>
      <p className={clsx(style.text, textModificator)} dangerouslySetInnerHTML={{ __html: text }} />
      <div className={clsx(style.img, imgModificator)} />
      <p
        className={clsx(style.text2, text2Modificator)}
        dangerouslySetInnerHTML={{ __html: text2 }}
      />
      {children}
    </div>
  )
}

export default Message
