import Button from '../Button/Button.js'
import style from './StartPage.module.scss'

const StartPage = ({ goNextStep }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.startPageTitle}>
        <p className={style.title1}>Верни</p>
        <p className={style.title2}>звезде</p>
        <p className={style.title3}>сияние</p>
      </div>
      <Button title="Начать игру" modificator={style.button} onClick={goNextStep} />
    </div>
  )
}

export default StartPage
