import { HeadProvider, Link } from 'react-head'
import img1 from '../src/assets/img/bg1.jpg'
import img2 from '../src/assets/img/bg10.jpg'
import img3 from '../src/assets/img/bg11.jpg'
import img4 from '../src/assets/img/bg12.jpg'
import img5 from '../src/assets/img/bg13.jpg'
import img6 from '../src/assets/img/bg14.jpg'
import img7 from '../src/assets/img/bg15.jpg'
import img8 from '../src/assets/img/bg16.jpg'
import img9 from '../src/assets/img/bg17.jpg'
import img10 from '../src/assets/img/bg18.jpg'
import img11 from '../src/assets/img/bg19.jpg'
import img12 from '../src/assets/img/bg2.jpg'
import img13 from '../src/assets/img/bg20.jpg'
import img14 from '../src/assets/img/bg21.jpg'
import img15 from '../src/assets/img/bg3.jpg'
import img16 from '../src/assets/img/bg4.jpg'
import img17 from '../src/assets/img/bg5.jpg'
import img18 from '../src/assets/img/bg6.jpg'
import img19 from '../src/assets/img/bg7.jpg'
import img20 from '../src/assets/img/bg8.jpg'
import img21 from '../src/assets/img/bg9.jpg'
import img22 from '../src/assets/img/blue_crystal.png'
import img23 from '../src/assets/img/brown_crystal.png'
import img24 from '../src/assets/img/btn_arrow.png'
import img25 from '../src/assets/img/correct.png'
import img26 from '../src/assets/img/correctBtn.png'
import img27 from '../src/assets/img/crystals.png'
import img28 from '../src/assets/img/green_crystal.png'
import img29 from '../src/assets/img/help_hand.png'
import img30 from '../src/assets/img/help_molekule.png'
import img31 from '../src/assets/img/incorrect.png'
import img32 from '../src/assets/img/incorrectBtn.png'
import img33 from '../src/assets/img/lili.png'
import img34 from '../src/assets/img/lorenzo.png'
import img35 from '../src/assets/img/man.png'
import img36 from '../src/assets/img/molekule.png'
import img37 from '../src/assets/img/pack1.png'
import img38 from '../src/assets/img/pack2.png'
import img39 from '../src/assets/img/pipe.png'
import img40 from '../src/assets/img/popupImg1.png'
import img41 from '../src/assets/img/popupImg2.png'
import img42 from '../src/assets/img/popupImg3.png'
import img43 from '../src/assets/img/popupImg4.png'
import img44 from '../src/assets/img/purple_crystal.png'
import img45 from '../src/assets/img/red_crystal.png'
import img46 from '../src/assets/img/robot.png'
import img47 from '../src/assets/img/title1.png'
import img48 from '../src/assets/img/title10.png'
import img49 from '../src/assets/img/title11.png'
import img50 from '../src/assets/img/title2.png'
import img51 from '../src/assets/img/title3.png'
import img52 from '../src/assets/img/title4.png'
import img53 from '../src/assets/img/title5.png'
import img54 from '../src/assets/img/title6.png'
import img55 from '../src/assets/img/title7.png'
import img56 from '../src/assets/img/title8.png'
import img57 from '../src/assets/img/title9.png'
import img58 from '../src/assets/img/video.mp4'
import img59 from '../src/assets/img/woman.png'
import img60 from '../src/assets/img/yellow_crystal.png'

const data = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
]

const Head = () => {
  return (
    <HeadProvider>
      {data.map((item, index) => (
        <Link rel="preload" href={item} as="image" key={index} />
      ))}
    </HeadProvider>
  )
}

export default Head
