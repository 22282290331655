import { useState } from 'react'
import Button from '../../../Button/Button.js'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'
import clsx from 'clsx'
import style from './Quarter3.module.scss'

const Quarter3 = ({ goNextQuarter, currentStep }) => {
  const [selectedContainer, setSelectedContainer] = useState(null)

  const correctHandler = () => {
    setSelectedContainer(2)
    setTimeout(() => {
      goNextQuarter()
    }, 1000)
  }

  const incorrectHandler = () => {
    setSelectedContainer(1)
  }

  return (
    <div className={style.wrapper}>
      <div
        className={clsx(style.checkBoxWrapper, {
          [style.incorrect]: selectedContainer === 1,
        })}
      >
        <div className={style.img1} />
        <div className={style.container}>
          <p className={style.title}>
            <b>Тюбик: </b>
            <span className={style.text}>
              пластик<br></br>
              <br></br>
            </span>
          </p>
          <p className={style.title}>
            <b>Коробка: </b>
            <span className={style.text}>картон, дополнительное покрытие лаком</span>
          </p>
          <Button
            title="выбрать"
            modificator2={clsx(style.button, {
              [style.incorrect]: selectedContainer === 1,
            })}
            onClick={incorrectHandler}
          />
        </div>
      </div>
      <div
        className={clsx(style.checkBoxWrapper, {
          [style.correct]: selectedContainer === 2,
        })}
      >
        <div className={style.img2} />
        <div className={style.container}>
          <p className={style.title}>
            <b>Тюбик: </b>
            <span className={style.text}>
              переработанный алюминий<br></br>
              <br></br>
            </span>
          </p>
          <p className={style.title}>
            <b>Коробка: </b>
            <span className={style.text}>переработанный картон</span>
          </p>
          <Button
            title="выбрать"
            modificator2={clsx(style.button, {
              [style.correct]: selectedContainer === 2,
            })}
            onClick={correctHandler}
          />
        </div>
      </div>
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter3
