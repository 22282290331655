import { useState, useEffect } from 'react'
import ProgressBar from '../../../ProgressBar/ProgressBar'
import clsx from 'clsx'
import style from './Quarter3.module.scss'

const Quarter3 = ({ goNextQuarter, currentStep }) => {
  const [checked, setChecked] = useState({ 1: false, 2: false, 3: false, 4: false })

  const handleCheckboxClick = (event) => {
    const target = event.target
    const checkbox = target.closest(`.${style.checkbox}`)
    if (checkbox) {
      const id = Number(checkbox.id)
      setChecked((prevState) => {
        const newState = { ...prevState, [id]: !prevState[id] }
        return newState
      })
    }
  }

  const isSuccess = checked[1] && checked[4]

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        goNextQuarter()
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [isSuccess, goNextQuarter])

  return (
    <div className={clsx(style.wrapper, { [style.blocked]: isSuccess })}>
      <p className={style.title}>
        {' '}
        Какие функии <br></br>выполняет меланин?
      </p>
      <div className={style.checkboxWrapper} onClick={handleCheckboxClick}>
        <div
          className={clsx(style.checkbox, {
            [style.correct]: checked[1],
          })}
          id="1"
        >
          <p className={style.checkboxText}>
            {' '}
            Отвечает<br></br> за цвет волос
          </p>
        </div>
        <div
          className={clsx(style.checkbox, {
            [style.incorrect]: checked[2],
          })}
          id="2"
        >
          <p className={style.checkboxText}> Нормализует сон</p>
        </div>
        <div
          className={clsx(style.checkbox, {
            [style.incorrect]: checked[3],
          })}
          id="3"
        >
          <p className={style.checkboxText}>
            {' '}
            Придаёт блеск<br></br> глазам
          </p>
        </div>
        <div
          className={clsx(style.checkbox, {
            [style.correct]: checked[4],
          })}
          id="4"
        >
          <p className={style.checkboxText}>
            {' '}
            Придаёт блеск<br></br> волосам
          </p>
        </div>
      </div>
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter3
