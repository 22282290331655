import Main from './pages/Main'
import Head from './Head'
import './assets/styles/App.scss'
import { useEffect, useState, useRef } from 'react'

function App() {
  const [scale, setScale] = useState(1)
  const ref = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = ref.current.offsetHeight
      const newScale = screenHeight > 960 ? screenHeight / 960 : 1
      setScale(newScale)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="App" ref={ref} style={{ transform: `scale(${scale})` }}>
      <Head />
      <Main />
    </div>
  )
}

export default App
