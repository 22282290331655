import { Fragment, useState, useEffect } from 'react'
import style from './Step5.module.scss'
import Button from '../../Button/Button.js'
import Title from '../../../assets/img/title9.png'
import Quarter1 from './Quarter1/Quarter1.js'
import Quarter2 from './Quarter2/Quarter2.js'
import Quarter3 from './Quarter3/Quarter3.js'
import Quarter4 from './Quarter4/Quarter4.js'
import ProgressBar from '../../ProgressBar/ProgressBar.js'

const Step4 = ({ goNextStep, currentStep }) => {
  const [quarter, setQuarter] = useState(0)

  const goNextQuarter = () => {
    setQuarter((prev) => prev + 1)
  }

  useEffect(() => {
    if (quarter === 1) {
      const timer = setTimeout(() => {
        setQuarter(2)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [quarter])

  return (
    <Fragment>
      {quarter === 0 && (
        <div className={style.wrapper}>
          <img className={style.title} src={Title} alt="title" />
          <Button
            title="5"
            modificator={style.buttonWrapper}
            modificator2={style.button}
            onClick={goNextQuarter}
          />
          <ProgressBar currentStep={currentStep} />
        </div>
      )}
      {quarter === 1 && <Quarter1 goNextQuarter={goNextQuarter} currentStep={currentStep} />}
      {quarter === 2 && <Quarter2 goNextQuarter={goNextQuarter} currentStep={currentStep} />}
      {quarter === 3 && <Quarter3 goNextQuarter={goNextQuarter} currentStep={currentStep} />}
      {quarter === 4 && <Quarter4 goNextStep={goNextStep} currentStep={currentStep} />}
    </Fragment>
  )
}

export default Step4
