import React, { useState, useEffect } from 'react'
import messageData from '../../../../constants/MessageData.js'
import Message from '../../../Message/Message.js'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'
import style from './Quarter3.module.scss'
import clsx from 'clsx'
import blueCrystal from '../../../../assets/img/blue_crystal.png'
import purpleCrystal from '../../../../assets/img/purple_crystal.png'
import redCrystal from '../../../../assets/img/red_crystal.png'
import greenCrystal from '../../../../assets/img/green_crystal.png'
import brownCrystal from '../../../../assets/img/brown_crystal.png'
import yellowCrystal from '../../../../assets/img/yellow_crystal.png'

const Quarter3 = ({ goNextQuarter, currentStep }) => {
  const [crystalObj, setCrystalObj] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  })

  const isSuccess = crystalObj[1] && crystalObj[3] && crystalObj[6]

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        goNextQuarter()
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [isSuccess, goNextQuarter])

  const handleCrystalClick = (event) => {
    const id = Number(event.target.id)
    setCrystalObj((prevState) => {
      const newState = { ...prevState, [id]: true }
      return newState
    })
    if (id === 2 || id === 4 || id === 5) {
      setTimeout(() => {
        setCrystalObj((prevState) => ({
          ...prevState,
          [id]: false,
        }))
      }, 500)
    }
  }

  return (
    <div className={clsx(style.wrapper, { [style.blocked]: isSuccess })}>
      <div className={style.crystalsWrapper}>
        <img
          className={clsx(style.blueCrystal, {
            [style.flyAway]: crystalObj[1],
          })}
          src={blueCrystal}
          alt="blueCrystal"
          id="1"
          onClick={handleCrystalClick}
        />
        <img
          className={clsx(style.purpleCrystal, {
            [style.shake]: crystalObj[2],
          })}
          src={purpleCrystal}
          alt="purpleCrystal"
          id="2"
          onClick={handleCrystalClick}
        />
        <img
          className={clsx(style.redCrystal, {
            [style.flyAway]: crystalObj[3],
          })}
          src={redCrystal}
          alt="redCrystal"
          id="3"
          onClick={handleCrystalClick}
        />
        <img
          className={clsx(style.greenCrystal, {
            [style.shake]: crystalObj[4],
          })}
          src={greenCrystal}
          alt="greenCrystal"
          id="4"
          onClick={handleCrystalClick}
        />
        <img
          className={clsx(style.brownCrystal, {
            [style.shake]: crystalObj[5],
          })}
          src={brownCrystal}
          alt="brownCrystal"
          id="5"
          onClick={handleCrystalClick}
        />
        <img
          className={clsx(style.yellowCrystal, {
            [style.flyAway]: crystalObj[6],
          })}
          src={yellowCrystal}
          alt="yellowCrystal"
          id="6"
          onClick={handleCrystalClick}
        />
      </div>
      <Message
        imgModificator={style.img}
        textModificator={style.text}
        wrapperModificator={style.messageWrapper}
        text={messageData[1].text}
      >
        <ProgressBar currentStep={currentStep} />
      </Message>
    </div>
  )
}

export default Quarter3
