import style from './Quarter1.module.scss'
import Man from '../../../../assets/img/man.png'
import Title from '../../../../assets//img/title2.png'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'

const Quarter1 = ({ currentStep }) => {
  return (
    <div className={style.wrapper}>
      <img className={style.title} src={Title} alt="title" />
      <img className={style.man} src={Man} alt="man" />
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter1
