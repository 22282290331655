import Message from '../../../Message/Message'
import Button from '../../../Button/Button'
import messageData from '../../../../constants/MessageData'
import ProgressBar from '../../../ProgressBar/ProgressBar'
import style from './Quarter4.module.scss'

const Quarter4 = ({ goNextStep, currentStep }) => {
  return (
    <div className={style.wrapper}>
      <Message
        text={messageData[2].text}
        text2={messageData[2].text2}
        wrapperModificator={style.messageWrapper}
        textModificator={style.text}
        text2Modificator={style.text2}
        imgModificator={style.img}
      >
        <Button
          title="Двигаться дальше"
          arrowModificator={style.arrow}
          modificator2={style.buttonWrapper}
          modificator={style.button}
          onClick={goNextStep}
        />
        <ProgressBar currentStep={currentStep} />
      </Message>
    </div>
  )
}

export default Quarter4
