import style from './FinalPage.module.scss'

const FinalPage = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.title} />
        <p className={style.text}>
          Секрет формулы яркости цвета и сияния Звезды открыт! <br></br>
          <br></br>Это <b>Dia Color от L`Oreal Professionnel Paris</b>. Больше ничто не помешает
          освещать межгалактические просторы!
        </p>
      </div>
    </div>
  )
}

export default FinalPage
