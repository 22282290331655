import { useState } from 'react'
import Button from '../Button/Button.js'
import clsx from 'clsx'
import style from './Popup.module.scss'

const Popup = ({ id, imgModificator, caption, text, onClick, modificator1 }) => {
  const [selectedButton, setSelectedButton] = useState(null)

  const handleClick = (e) => {
    const target = e.target

    const buttonText = target ? target.textContent : ''

    setSelectedButton(buttonText)
    onClick(e)
  }

  return (
    <div className={style.popupWrapper} id={id}>
      <div className={clsx(style.img, imgModificator)} />
      <p className={style.caption} dangerouslySetInnerHTML={{ __html: caption }} />
      <p className={style.text} dangerouslySetInnerHTML={{ __html: text }} />
      <div className={style.buttonContainer}>
        <Button
          title="взять"
          modificator2={clsx(style.button, { [modificator1]: selectedButton === 'взять' })}
          onClick={handleClick}
        />
        <Button
          title="не брать"
          modificator2={clsx(style.button, { [modificator1]: selectedButton === 'не брать' })}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}

export default Popup
