import ProgressBar from '../../../ProgressBar/ProgressBar'
import style from './Quarter1.module.scss'
import Robot from '../../../../assets/img/robot.png'
import Title from '../../../../assets//img/title10.png'

const Quarter1 = ({ currentStep }) => {
  return (
    <div className={style.wrapper}>
      <img className={style.title} src={Title} alt="title" />
      <img className={style.robot} src={Robot} alt="robot" />
      <ProgressBar currentStep={currentStep} />
    </div>
  )
}

export default Quarter1
