const popupData = [
  {
    caption: 'Бурые пальмы с Суперземли производят готовый растительный глицерин.',
    text: 'Глицерин сохраняет влагу в волосах, делает их более мягкими и гладкими.',
  },
  {
    caption: 'Гигантская биоплёнка с планеты-океана вырабатывает гиалуроновую кислоту.',
    text: 'Гиалуроновая кислота увлажняет и поддерживает здоровье кожи головы. От состояния кожи головы зависит рост волос.',
  },
  {
    caption:
      'Борщевик древовидный с планеты Проксима-бета содержит большое количество фурокумарина.',
    text: 'Фурокумарин увеличивает чувствительность кожи к ультрафиолету. Обычно его применяют жители планет с очень низким уровнем УФ-излучения.',
  },
  {
    caption:
      'Порода дерева ши, выведенная в нашем саду, даёт много масла, несмотря на скормные размеры.',
    text: 'Масло ши богато витаминами и жирными кислотами, они восстанавливают и питают. Борется с сухостью и ломкостью волос, делая их более мягкими и послушными.',
  },
]

export default popupData
