import Message from '../../../Message/Message.js'
import Button from '../../../Button/Button.js'
import messageData from '../../../../constants/MessageData.js'
import ProgressBar from '../../../ProgressBar/ProgressBar.js'
import style from './Quarter2.module.scss'

const Quarter2 = ({ goNextQuarter, currentStep }) => {
  return (
    <div className={style.wrapper}>
      <Message imgModificator={style.img} text={messageData[8].text}>
        <Button
          title="Прогуляться по саду"
          modificator2={style.buttonWrapper}
          arrowModificator={style.arrow}
          onClick={goNextQuarter}
        />
        <ProgressBar currentStep={currentStep} />
      </Message>
    </div>
  )
}

export default Quarter2
